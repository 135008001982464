.apt-button-primary {
  background-color: #1A7DFA;
  color: #FFFFFF;

  &:hover {
    background-color: #0D62CD;
    cursor: pointer;
  }
}

.apt-button-secundary {
  background-color: #6C757D;
  color: #FFFFFF;

  &:hover {
    background-color: #586169;
    cursor: pointer;
  }
}
.apt-button {
  display: inline-block;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-width: 15em;

  @media (max-width: 768px) { // For tablets and mobile devices
    padding: 8px 16px;
    min-width: 80px;
  }

  @media (max-width: 480px) { // For mobile devices
    padding: 6px 12px;
    min-width: 60px;
  }
}
